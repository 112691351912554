.main {
  margin: 3rem auto;
  width: 90%;
  max-width: 90rem;
}

  .pageTitle {
    text-align: center;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center
  }
  img {
    width: 100%;
    object-fit: cover;
  }
  .image {
    flex-basis: 40%
  }
  .text {
    text-decoration: none;
    font-size: 1.25rem;
    color: #fefcff;
    padding-bottom: 1.25rem;
  }

  .text a {
    text-decoration: none;
    font-size: 1.25rem;
    color: #fefcff;
    text-align: center;
  }

  .text a:hover,
  .text a:active,
  .footextter a.active {
    color: #9e9e9e;
  }