.footer {
  text-decoration: none;
  font-size: 1rem;
  color: #fefcff;
  text-align: center;
  padding-bottom: 5rem;
}

.footer a {
    text-decoration: none;
    font-size: 1rem;
    color: #fefcff;
    text-align: center;
  }

  .footer a:hover,
  .footer a:active,
  .footer a.active {
    color: #9e9e9e;
  }

