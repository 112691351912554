/*  
black: #010101
white pointer: #fefcff
purple heart: #5d20d3
silver chalice: #9e9e9e
meteorite: #321b6c
mauve: #c9a2ff
aquamarine blue: #71e1e1
orange peel: #f59f01
calypso: #377a94
berick red: #c82a33
*/

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

body::-webkit-scrollbar {
  width: 0.7em;
  background: #321b6c;
}
body::-webkit-scrollbar-thumb {
  background: #5d20d3;
  height:30px;
}

body::-webkit-scrollbar-track-piece
{
   display:none;
}


* {
  box-sizing: border-box;
}

html {
  height: 100%;
  overflow-y: scroll;
}

body {
  font-family: 'Open Sans', sans-serif;
  height: 100%;
  margin: 0;
  background: #5d20d3;
  background: linear-gradient(45deg, #5d20d3 0%, #321b6c 100%);
  background-repeat: no-repeat;
  background-attachment: fixed;
}

h1 {
  font-size: 2.5rem;
  color: #fefcff;
}

h2 {
  font-size: 1.5rem;
  color: #fefcff;
}

h3 {
  color: #fefcff;
}

h4 {
  color: #fefcff;
}

p {
  font-size: 1rem;
  color: #321b6c;
}

.centered {
  margin: 3rem auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
